
import { Component, Vue } from 'vue-property-decorator';
import SaioMap from '@/views/about-saio/SaioMap.vue';
import WantKnowMore from '@/components/WantKnowMore.vue';

@Component({
  components: {
    SaioMap,
    WantKnowMore,
  },
})
export default class AboutSaio extends Vue {
  public nextPage = 'technology';
}
